import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Storage } from 'aws-amplify';

const { REACT_APP_API_ENDPOINT, REACT_APP_BUCKET_BASE_URL } = process.env;

export const setSelectedArticle = createAction('articles/SET_SELECTED_ARTICLE');

export const setArticlesList = createAsyncThunk(
  'articles/SET_ARTICLES',
  async (a, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${REACT_APP_API_ENDPOINT}/articles`, {});
      const data = response.data.map((article) => {
        return {
          ...article,
          createdAt: new Date(article.createdAt).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
        };
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setActiveCategory = createAction('articles/SET_ACTIVE_CATEGORY');

export const setShowList = createAction('articles/SET_SHOW_LIST');

export const createArticle = createAsyncThunk(
  'articles/CREATE_ARTICLE',
  async (article, { getState, rejectWithValue }) => {
    const state = getState();
    const { title, image_url: imageFile } = article;
    const imageType = imageFile.type.split('/').pop();
    const imageName = `${title}.${imageType}`;

    await Storage.put(imageName, imageFile, {
      contentType: imageFile.type,
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    });

    try {
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/article`,
        {
          article: { ...article, image_url: `${REACT_APP_BUCKET_BASE_URL}/${imageName}` },
        },
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      const receivedArticle = response.data;

      return {
        ...receivedArticle,
        createdAt: new Date(receivedArticle.createdAt).toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateArticle = createAsyncThunk(
  'articles/UPDATE_ARTICLE',
  async (article, { getState, rejectWithValue }) => {
    const state = getState();
    const { title, image_url: imageFile } = article;
    let revisedArticle = article;

    if (typeof imageFile !== 'string') {
      const imageType = imageFile.type.split('/').pop();
      const imageName = `${title}.${imageType}`;

      await Storage.put(imageName, imageFile, {
        contentType: imageFile.type,
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      });

      revisedArticle = { ...article, image_url: `${REACT_APP_BUCKET_BASE_URL}/${imageName}` };
    }

    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/article`,
        { revisedArticle },
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      const receivedArticle = response.data;

      return {
        ...receivedArticle,
        createdAt: new Date(receivedArticle.createdAt).toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeArticle = createAsyncThunk(
  'articles/REMOVE_ARTICLE',
  async (articleId, { getState, rejectWithValue }) => {
    const state = getState();

    try {
      const response = await axios.delete(`${REACT_APP_API_ENDPOINT}/article`, {
        headers: {
          Authorization: state.auth.token,
        },
        data: { articleId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setNewArticle = createAction('articles/SET_NEW_ARTICLE');

export const setArticleToEdit = createAction('articles/SET_ARTICLE_TO_EDIT');

export const clearArticlesError = createAction('articles/CLEAR_ERROR');
