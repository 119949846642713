import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import { logOut } from 'store/auth/actions';
import { getAuthState, getAuthIsPending } from 'store/auth/selectors';
import SpinnerEffect from 'components/Spinner';
import './LogoutButton.scss';

const LogoutButton = ({ isAuthenticated, logOut, isAuthPending }) => {
  const handleLogout = (e) => {
    e.preventDefault();
    logOut();
  };

  return (
    isAuthenticated && (
      <div className="LogoutButtonContainer">
        <Button size="sm" variant="danger" onClick={(e) => handleLogout(e)}>
          Logout <SpinnerEffect showSpinner={isAuthPending} />
        </Button>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getAuthState(state),
  isAuthPending: getAuthIsPending(state),
});

const mapDispatchToProps = {
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton);
