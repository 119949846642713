import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorModal = ({ error, onErrorModalClose }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error !== null) {
      setOpen(error !== null);
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);
    onErrorModalClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={3500} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {error?.message || 'A apărut o eroare neașteptată'}
      </Alert>
    </Snackbar>
  );
};

export default ErrorModal;
