import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CardContentLoader(props) {
  return (
    <ContentLoader viewBox="0 0 345 180" height={'95%'} width={'95%'} {...props}>
      <rect x="10" y="3" rx="10" ry="10" width="325" height="115" />
      <rect x="10" y="125" rx="0" ry="0" width="292" height="15" />
      <rect x="10" y="145" rx="0" ry="0" width="239" height="12" />
      <rect x="10" y="160" rx="0" ry="0" width="274" height="12" />
    </ContentLoader>
  );
}
