import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { setHideEditModal } from 'store/modals/actions';
import { getShowEditModal } from 'store/modals/selectors';
import {
  setSelectedArticle,
  createArticle,
  updateArticle,
  setNewArticle,
} from 'store/articles/actions';
import {
  getSelectedArticle,
  getArticlesRequestIsPending,
  getNewArticle,
} from 'store/articles/selectors';
import SpinnerEffect from 'components/Spinner';

import { categories } from 'utils';

import './ArticleModal.scss';

const ArticleModal = ({
  show,
  selectedArticle,
  isRequestPending,
  newArticle,
  newArticle: { id, title, category, image_url, text, author },
  setHideEditModal,
  setSelectedArticle,
  createArticle,
  updateArticle,
  setNewArticle,
}) => {
  const emptyArticle = {
    title: '',
    category: '',
    image_url: '',
    text: '',
    author: '',
  };

  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    setNewArticle(selectedArticle ? selectedArticle : emptyArticle);
  }, [selectedArticle]);

  const handleClose = () => {
    setHideEditModal();
    setNewArticle(emptyArticle);
    setSelectedArticle(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    id ? updateArticle(newArticle) : createArticle(newArticle);
  };

  const handleChange = (property, value) => {
    setNewArticle({ ...newArticle, [property]: value });

    if (property === 'image_url' && value !== '') {
      setIsImage(true);
    }
  };

  function validateForm() {
    return (
      title.length > 0 && text.length > 0 && author.length > 0 && category.length && isImage > 0
    );
  }

  const renderArticleForm = () => {
    function handleSubmit(event) {
      event.preventDefault();
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group key="title" size="lg" controlId="title" className="mb-3">
          <Form.Label>Titlu</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={title}
            onChange={(e) => handleChange('title', e.target.value)}
          />
        </Form.Group>
        <Form.Group key="category" size="lg" controlId="category" className="mb-3">
          <Form.Label>Categorie</Form.Label>
          <Form.Select
            value={category}
            onChange={(e) => handleChange('category', e.target.value)}
            className="FormCategorySelect"
          >
            <option>Alege categoria...</option>
            {categories.map(({ eventKey, name }) => (
              <option value={eventKey}>{name}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group key="image" controlId="formFile" className="mb-3">
          <Form.Label>Adaugă o imagine</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleChange('image_url', e.target.files[0])}
          />
        </Form.Group>
        <Form.Group key="article-text" className="mb-3" controlId="text">
          <Form.Label>Textul articolului</Form.Label>
          <Form.Control
            as="textarea"
            value={text}
            rows={12}
            onChange={(e) => handleChange('text', e.target.value)}
          />
        </Form.Group>
        <Form.Group key="author" size="lg" controlId="author">
          <Form.Label>Autor</Form.Label>
          <Form.Control
            type="text"
            value={author}
            onChange={(e) => handleChange('author', e.target.value)}
          />
        </Form.Group>
      </Form>
    );
  };

  return (
    <Modal size="lg" fullscreen="sm-down" backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{id ? 'Editează Articolul' : 'Creează un Articol Nou'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderArticleForm()}</Modal.Body>
      <Modal.Footer>
        <Button key="cancel" variant="secondary" onClick={handleClose}>
          Anulează
        </Button>
        <Button
          key="submit"
          variant="success"
          block="true"
          type="submit"
          disabled={!validateForm()}
          onClick={(e) => handleSubmit(e)}
        >
          Salvează <SpinnerEffect showSpinner={isRequestPending} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: getShowEditModal(state),
  selectedArticle: getSelectedArticle(state),
  isRequestPending: getArticlesRequestIsPending(state),
  newArticle: getNewArticle(state),
});

const mapDispatchToProps = {
  setHideEditModal,
  setSelectedArticle,
  createArticle,
  updateArticle,
  setNewArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleModal);
