import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { setHideDeleteModal } from 'store/modals/actions';
import { getShowDeleteModal } from 'store/modals/selectors';
import { setSelectedArticle, removeArticle } from 'store/articles/actions';
import { getSelectedArticleId } from 'store/articles/selectors';

const DeleteModal = ({
  show,
  selectedArticleId,
  setHideDeleteModal,
  setSelectedArticle,
  removeArticle,
}) => {
  const handleClose = () => {
    setHideDeleteModal();
    setSelectedArticle(undefined);
  };

  const handleDelete = () => {
    removeArticle(selectedArticleId);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ești pe cale să ștergi articolul.</Modal.Title>
      </Modal.Header>
      <Modal.Body>Ești sigur că dorești ștergerea acestui articol?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Anulează
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Șterge
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: getShowDeleteModal(state),
  selectedArticleId: getSelectedArticleId(state),
});

const mapDispatchToProps = {
  setHideDeleteModal,
  setSelectedArticle,
  removeArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
