export const getAuthState = (state) => {
  return state.auth.isAuthenticated;
};

export const getAuthIsPending = (state) => {
  return state.auth.isPending;
};

export const getAuthError = (state) => {
  return state.auth.error;
};
