import React, { Fragment } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TextField from '@mui/material/TextField';

import { isEmpty } from 'lodash';

import ArticleContentLoader from 'components/ArticleContentLoader';
import './ArticleCard.scss';

export default function ArticleCard({ content = {} }) {
  const { author, title, text } = content;
  const publishDate = content?.createdAt;
  const image = content?.image_url;

  const renderCardSubheader = () => {
    return (
      <Fragment>
        <CalendarMonthOutlinedIcon />
        {' ' + publishDate + ' '}
        <PersonOutlineOutlinedIcon />
        <b>{' ' + author}</b>
      </Fragment>
    );
  };

  const renderCardContent = () => {
    return isEmpty(content) ? (
      <ArticleContentLoader />
    ) : (
      <>
        <CardHeader title={title} subheader={renderCardSubheader()} />
        <CardMedia component="img" image={image} alt="news image" />
        <CardContent>
          <TextField
            defaultValue={text}
            inputProps={{
              style: { textAlign: 'justify' },
            }}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            multiline
          />
        </CardContent>
      </>
    );
  };

  return <Card className="ArticleCard">{renderCardContent()}</Card>;
}
