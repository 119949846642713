import { createReducer } from '@reduxjs/toolkit';
import { authenticateUser, setAuthFromSession, logOut, clearAuthError } from './actions';

const initialState = {
  token: '',
  isAuthenticated: false,
  isPending: false,
  error: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(authenticateUser.pending, (state, action) => {
      state.isPending = true;
      return state;
    })
    .addCase(authenticateUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload?.signInUserSession?.idToken?.jwtToken;
      state.isPending = false;
      return state;
    })
    .addCase(authenticateUser.rejected, (state, action) => {
      state.error = action.payload;
      state.isPending = false;
      return state;
    })
    .addCase(setAuthFromSession.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload?.idToken?.jwtToken;
      return state;
    })
    .addCase(setAuthFromSession.rejected, (state, action) => {
      return state;
    })
    .addCase(logOut.pending, (state, action) => {
      state.isPending = true;
      return state;
    })
    .addCase(logOut.fulfilled, (state, action) => {
      state.isAuthenticated = false;
      state.token = '';
      state.isPending = false;
      return state;
    })
    .addCase(logOut.rejected, (state, action) => {
      state.error = action.payload;
      state.isPending = false;
      return state;
    })
    .addCase(clearAuthError, (state, action) => {
      state.error = initialState.error;
    })
    .addDefaultCase((state, action) => {});
});
