import React from 'react';
import './MainSection.scss';

export default function MainSection({ children }) {
  return (
    <div className="MainSection">
      <div className="MainSectionContentContainer">{children}</div>
    </div>
  );
}
