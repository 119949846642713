import React from 'react';

import Header from 'pages/home/components/ArticlesLayout/components/Header';
import MainSection from 'pages/home/components/ArticlesLayout/components/MainSection';

import './ArticlesLayout.scss';

export default function ArticlesLayout({ children }) {
  return (
    <div className="ArticlesLayout">
      <div className="HeaderWrapper">
        <Header />
      </div>
      <div className="ArticlesWrapper">
        <MainSection>{children}</MainSection>
      </div>
    </div>
  );
}
