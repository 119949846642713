import React from 'react';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import Button from 'react-bootstrap/Button';

import DisplayCard from 'components/DisplayCard';
import { setSelectedArticle, setShowList } from 'store/articles/actions';
import { getArticles, getShowList } from 'store/articles/selectors';
import { setShowDeleteModal, setShowEditModal } from 'store/modals/actions';
import { getAuthState } from 'store/auth/selectors';

const ArticlesList = ({
  show,
  articles,
  setSelectedArticle,
  setShowList,
  setShowDeleteModal,
  setShowEditModal,
  userIsAdmin,
}) => {
  const handleClickArticle = (id) => {
    setSelectedArticle(id);
    setShowList(false);
  };

  const handleCreateArticle = (e) => {
    e.preventDefault();
    setShowEditModal();
  };

  const handleEditArticle = (id) => {
    setSelectedArticle(id);
    setShowEditModal();
  };

  const handleDeleteArticle = (id) => {
    setSelectedArticle(id);
    setShowDeleteModal();
  };

  return (
    isArray(articles) &&
    show && (
      <div className="ArticlesListContainer">
        {userIsAdmin && (
          <Button
            variant="outline-dark"
            size="lg"
            className="mb-3"
            onClick={(e) => handleCreateArticle(e)}
          >
            Creează un Articol Nou +
          </Button>
        )}
        {articles.map((article, index) => {
          return (
            <DisplayCard
              key={index}
              content={article}
              handleEdit={() => handleEditArticle(article.id)}
              handleDelete={() => handleDeleteArticle(article.id)}
              handleClickArticle={() => handleClickArticle(article.id)}
            />
          );
        })}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  show: getShowList(state),
  userIsAdmin: getAuthState(state),
});

const mapDispatchToProps = {
  setSelectedArticle,
  setShowList,
  setShowDeleteModal,
  setShowEditModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesList);
