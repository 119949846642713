import React from 'react';
import { connect } from 'react-redux';

import ArticleCard from 'components/ArticleCard';
import { getSelectedArticle, getShowList } from 'store/articles/selectors';

const Article = ({ show, article }) => {
  return (
    show && (
      <div className="ArticleContainer">
        <ArticleCard content={article} />
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  article: getSelectedArticle(state),
  show: !getShowList(state),
});

export default connect(mapStateToProps, null)(Article);
