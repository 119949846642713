import { createReducer } from '@reduxjs/toolkit';
import {
  setShowDeleteModal,
  setHideDeleteModal,
  setShowEditModal,
  setHideEditModal,
} from './actions';
import { createArticle, updateArticle } from 'store/articles/actions';

const initialState = {
  showDeleteModal: false,
  showEditModal: false,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setShowDeleteModal, (state, action) => {
      state.showDeleteModal = true;
      return state;
    })
    .addCase(setHideDeleteModal, (state, action) => {
      state.showDeleteModal = false;
      return state;
    })
    .addCase(setShowEditModal, (state, action) => {
      state.showEditModal = true;
      return state;
    })
    .addCase(setHideEditModal, (state, action) => {
      state.showEditModal = false;
      return state;
    })
    .addCase(createArticle.fulfilled, (state, action) => {
      state.showEditModal = false;
    })
    .addCase(updateArticle.fulfilled, (state, action) => {
      state.showEditModal = false;
    })
    .addDefaultCase((state, action) => {});
});
