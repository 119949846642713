import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from 'pages/home';
import Login from 'pages/login';
import { setAuthFromSession } from 'store/auth/actions';

import './App.scss';

function App({ setAuthFromSession }) {
  useEffect(() => {
    setAuthFromSession();
  }, []);

  return (
    <Router>
      <div className="App">
        <div className="PageWrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

const mapDispatchToProps = {
  setAuthFromSession,
};

export default connect(null, mapDispatchToProps)(App);
