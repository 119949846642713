import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SpinnerEffect from 'components/Spinner';
import ErrorModal from 'components/ErrorModal';

import { authenticateUser, clearAuthError } from 'store/auth/actions';
import { getAuthState, getAuthIsPending, getAuthError } from 'store/auth/selectors';

import './Login.scss';

const Login = ({
  isAuthenticated,
  authenticateUser,
  isAuthPending,
  clearAuthError,
  error,
  ...props
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const nav = useNavigate();

  useEffect(() => {
    isAuthenticated && nav('/');
  }, [isAuthenticated]);

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    authenticateUser({ username, password });
  };

  const handleErrorModalClose = () => {
    setTimeout(clearAuthError, 1000);
  };

  return (
    <div className="LoginPageContainer">
      <div className="LoginHeader">AUTENTIFICARE</div>
      <div className="LoginFormContainer">
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="username">
            <Form.Label>Utilizator</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Parolă</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button block="true" size="lg" type="submit" disabled={!validateForm()}>
            Autentifică <SpinnerEffect showSpinner={isAuthPending} />
          </Button>
        </Form>
      </div>
      <ErrorModal error={error} onErrorModalClose={handleErrorModalClose} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: getAuthState(state),
  isAuthPending: getAuthIsPending(state),
  error: getAuthError(state),
});

const mapDispatchToProps = { authenticateUser, clearAuthError };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
