import React, { useRef, useEffect } from 'react';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const categories = [
  { eventKey: 'home', title: 'Acasă', name: 'Acasă' },
  { eventKey: 'basarabia', title: 'Basarabia – pământ românesc', name: 'Basarabia' },
  { eventKey: 'vocea', title: 'Vocea unionistului', name: 'Unionistul' },
  { eventKey: 'interne', title: 'Actualitate internă', name: 'Interne' },
  { eventKey: 'externe', title: 'Externe', name: 'Externe' },
  {
    eventKey: 'lumea_si_noi',
    title: 'Lumea și noi',
    name: 'Lumea și noi',
  },
];
