import { configureStore } from '@reduxjs/toolkit';

import reducer from './reducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'auth/AUTHENTICATE_USER/fulfilled',
          'auth/SET_AUTH_FROM_SESSION/fulfilled',
          'articles/SET_NEW_ARTICLE',
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: [`articles.newArticle.image_url`],
      },
    }),
});

export default store;
