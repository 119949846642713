import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';

import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_IDENTITY_POOL_ID,
  REACT_APP_BUCKET,
} = process.env;

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    identityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      region: REACT_APP_AWS_REGION,
      bucket: REACT_APP_BUCKET,
      identityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
