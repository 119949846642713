import { createReducer } from '@reduxjs/toolkit';
import {
  setArticlesList,
  setSelectedArticle,
  setActiveCategory,
  setShowList,
  removeArticle,
  createArticle,
  updateArticle,
  setNewArticle,
  clearArticlesError,
} from './actions';

const emptyArticle = {
  title: '',
  category: '',
  image_url: '',
  text: '',
  author: '',
};

const initialState = {
  articlesList: [],
  activeCategory: 'home',
  showList: true,
  pendingArticle: {},
  selectedArticleId: '',
  isPending: false,
  newArticle: { ...emptyArticle },
  error: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setArticlesList.pending, (state, action) => {
      state.isPending = true;
      return state;
    })
    .addCase(setArticlesList.fulfilled, (state, action) => {
      state.articlesList = action.payload;
      state.isPending = false;
      return state;
    })
    .addCase(setArticlesList.rejected, (state, action) => {
      state.error = action.payload;
      state.isPending = false;
      return state;
    })
    .addCase(setSelectedArticle, (state, action) => {
      state.selectedArticleId = action.payload;
      return state;
    })
    .addCase(setActiveCategory, (state, action) => {
      state.activeCategory = action.payload;
      return state;
    })
    .addCase(setShowList, (state, action) => {
      state.showList = action.payload;
      return state;
    })
    .addCase(createArticle.pending, (state, action) => {
      state.isPending = true;
      return state;
    })
    .addCase(createArticle.fulfilled, (state, action) => {
      state.articlesList.push(action.payload);
      state.newArticle = { ...emptyArticle };
      state.selectedArticleId = undefined;

      state.isPending = false;
      return state;
    })
    .addCase(createArticle.rejected, (state, action) => {
      state.error = action.payload;
      state.isPending = false;

      return state;
    })
    .addCase(updateArticle.pending, (state, action) => {
      state.isPending = true;
      return state;
    })
    .addCase(updateArticle.fulfilled, (state, action) => {
      const revisedArticle = action.payload;
      const updatedArticleIndex = state.articlesList.findIndex(
        (article) => article.id === revisedArticle.id
      );
      state.articlesList.splice(updatedArticleIndex, 1, revisedArticle);
      state.newArticle = { ...emptyArticle };
      state.selectedArticleId = undefined;

      state.isPending = false;
      return state;
    })
    .addCase(updateArticle.rejected, (state, action) => {
      state.error = action.payload;
      state.isPending = false;
      return state;
    })
    .addCase(removeArticle.pending, (state, action) => {
      const { selectedArticleId } = state;
      state.isPending = true;
      state.pendingArticle = {
        id: selectedArticleId,
        content: state.articlesList.find((article) => article.id === selectedArticleId),
      };
      state.articlesList = state.articlesList.filter((article) => article.id !== selectedArticleId);

      return state;
    })
    .addCase(removeArticle.fulfilled, (state, action) => {
      state.pendingArticle = {};
      state.isPending = false;
      return state;
    })
    .addCase(removeArticle.rejected, (state, action) => {
      state.error = action.payload;

      const { content } = state.pendingArticle;
      state.articlesList.push(content);
      state.pendingArticle = {};
      state.isPending = false;
      return state;
    })
    .addCase(setNewArticle, (state, action) => {
      state.newArticle = { ...action.payload };
      return state;
    })
    .addCase(clearArticlesError, (state, action) => {
      state.error = initialState.error;
    })
    .addDefaultCase((state, action) => {});
});
