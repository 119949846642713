import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';

import { setActiveCategory, setShowList, setSelectedArticle } from 'store/articles/actions';
import { getActiveCategory } from 'store/articles/selectors';
import LogoutButton from 'pages/home/components/ArticlesLayout/components/LogoutButton';

import { categories } from 'utils';

import './Header.scss';

const Header = ({ activeTab, setTab, setShowList, setSelectedArticle }) => {
  const [headerExpanded, setHeaderExpanded] = useState(false);

  const handleTabClick = (tab) => {
    setTab(tab);
    setHeaderExpanded(false);
    setShowList(true);
    setSelectedArticle(undefined);
  };

  return (
    <div className="Header">
      <div className="TitleContainer">REUNIREA</div>
      <div className="ButtonsContainer">
        <Navbar expand="sm" expanded={headerExpanded}>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setHeaderExpanded(!headerExpanded)}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Tab.Container
              activeKey={activeTab}
              defaultActiveKey="home"
              onSelect={(tab) => handleTabClick(tab)}
            >
              <Nav variant="pills">
                {categories.map(({ eventKey, name }) => {
                  return (
                    <Nav.Item key={eventKey}>
                      <Nav.Link eventKey={eventKey}>{name}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Tab.Container>
          </Navbar.Collapse>
        </Navbar>
        <LogoutButton />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeTab: getActiveCategory(state),
});

const mapDispatchToProps = {
  setTab: setActiveCategory,
  setShowList,
  setSelectedArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
