import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function SpinnerEffect({ showSpinner }) {
  return (
    showSpinner && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        variant="light"
      />
    )
  );
}
