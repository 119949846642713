import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';

import { getAuthState } from 'store/auth/selectors';

import './BurgerMenu.scss';

const BurgerMenu = ({ handleEdit, handleDelete, userIsAdmin }) => {
  const handleClickEdit = (e) => {
    e.preventDefault();
    handleEdit();
  };

  const handleClickDelete = (e) => {
    e.preventDefault();
    handleDelete();
  };

  return (
    userIsAdmin && (
      <Dropdown className="BurgerMenu">
        <Dropdown.Toggle as={MoreVertIcon} />
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={handleClickEdit}>
            Editează
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={handleClickDelete}>
            Șterge
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  );
};

const mapStateToProps = (state) => ({
  userIsAdmin: getAuthState(state),
});

export default connect(mapStateToProps, null)(BurgerMenu);
