import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ArticlesLayout from 'pages/home/components/ArticlesLayout';
import EditArticleModal from 'pages/home/components/ArticleModal';
import DeleteModal from 'pages/home/components/DeleteModal';
import ArticlesList from 'pages/home/components/ArticlesList';
import Article from 'pages/home/components/Article';
import ErrorModal from 'components/ErrorModal';

import { setArticlesList, clearArticlesError } from 'store/articles/actions';
import { getArticleError } from 'store/articles/selectors';

const Home = ({ setArticles, clearArticlesError, error, ...props }) => {
  useEffect(() => {
    setArticles();
  }, []);

  const handleErrorModalClose = () => {
    setTimeout(clearArticlesError, 1000);
  };

  return (
    <ArticlesLayout>
      <ArticlesList />
      <Article />
      <EditArticleModal />
      <DeleteModal />
      <ErrorModal error={error} onErrorModalClose={handleErrorModalClose} />
    </ArticlesLayout>
  );
};

const mapDispatchToProps = {
  setArticles: setArticlesList,
  clearArticlesError,
};

const mapStateToProps = (state) => ({
  error: getArticleError(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
