import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { isEmpty } from 'lodash';

import CardContentLoader from 'components/CardContentLoader';
import BurgerMenu from 'components/BurgerMenu';
import './DisplayCard.scss';

export default function DisplayCard({ content = {}, handleClickArticle, ...props }) {
  const { title, text } = content;
  const publishDate = content?.createdAt;
  const image = content?.image_url;

  return isEmpty(content) ? (
    <CardContentLoader />
  ) : (
    <Card className="DisplayCard">
      <CardActionArea onClick={handleClickArticle}>
        <CardMedia component="img" height="140" image={image} alt="image not available" />
      </CardActionArea>
      <CardContent>
        <div className="CardContentHeader">
          <CardActionArea onClick={handleClickArticle}>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
          </CardActionArea>
          <BurgerMenu {...props} />
        </div>
        <Typography variant="subtitle2" component="div">
          {publishDate}
        </Typography>
        <CardActionArea onClick={handleClickArticle}>
          <Typography className="CardTextContent" variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardActionArea>
      </CardContent>
    </Card>
  );
}
