import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export const authenticateUser = createAsyncThunk(
  'auth/AUTHENTICATE_USER',
  async (params, { rejectWithValue }) => {
    const { username, password } = params;

    try {
      const user = await Auth.signIn(username, password);

      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        try {
          await Auth.completeNewPassword(user, password);
        } catch (error) {
          return rejectWithValue({ message: error.message });
        }
      }

      return user;
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const setAuthFromSession = createAsyncThunk(
  'auth/SET_AUTH_FROM_SESSION',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Auth.currentSession();
      return response;
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const logOut = createAsyncThunk('auth/LOG_OUT', async (_, { rejectWithValue }) => {
  try {
    const response = await Auth.signOut();
    return response;
  } catch (error) {
    return rejectWithValue({ message: error.message });
  }
});

export const clearAuthError = createAction('auth/CLEAR_ERROR');
