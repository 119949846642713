export const getArticles = (state) => {
  const { articlesList, activeCategory } = state.articles;
  const filteredList = articlesList.filter(
    (article) => activeCategory === 'home' || article.category === activeCategory
  );
  const sortedList = filteredList.sort(
    (artA, artB) => Date.parse(artB.createdAt) - Date.parse(artA.createdAt)
  );
  return sortedList;
};

export const getSelectedArticle = (state) => {
  const { selectedArticleId } = state.articles;

  return state.articles.articlesList.find((article) => article.id === selectedArticleId);
};

export const getSelectedArticleId = (state) => {
  return state.articles.selectedArticleId;
};

export const getActiveCategory = (state) => {
  return state.articles.activeCategory;
};

export const getShowList = (state) => {
  return state.articles.showList;
};

export const getArticlesRequestIsPending = (state) => {
  return state.articles.isPending;
};

export const getNewArticle = (state) => {
  return state.articles.newArticle;
};

export const getArticleError = (state) => {
  return state.articles.error;
};
